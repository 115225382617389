.splash-page {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2500;

  &__content {
    text-align: center;
    color: var(--color-primary);

    h1 {
      font-family: "Wallpoet", cursive;
      margin-bottom: 20px;
      font-size: 48px;
    }

    h2 {
      font-family: sans-serif;
      font-size: 24px;
    }
  }
}
