.pushBtn:active {
  transform: scale(0.97);
}
#nopad {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.force-negative-margins {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

@media screen and (max-width: 480px) {
  #social-titles {
    font-size: 8pt !important;
  }
  #form-text {
    font-size: 10pt !important;
  }
}

.save-instructions {
  font-family: "Wallpoet", cursive;
  font-size: 10pt;
  color: rgba(129, 19, 0, 0.966);
}

.expandable:focus {
  /* position: absolute !important; */
  width: 100% !important;
  z-index: 2000;
  transition: all 0.5s;
}

.card-header:hover {
  cursor: pointer;
}

.click-box:hover {
  /* box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5); */
  cursor: pointer;
  transform: scale(1.2);
  /* border-radius: 8px; */
}

.profile-container {
  position: relative;
  display: inline-block;
}

.upload-button {
  position: absolute;
  width: 24%;
  height: auto;
  bottom: 3%;
  right: 3%;  
  background-color: rgb(219, 215, 215);
  border: 4px solid #fffdfd; /* Adjust border as needed */
  border-radius: 50%;
  padding: 5%;
  cursor: pointer;
}

.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px; /* Adjust icon container size as needed */
  height: 24px; /* Adjust icon container size as needed */
  background-color: white; /* Optional: Add background color */
  border-radius: 50%;
  margin-right: 8px; /* Adjust spacing as needed */
}

.container {
  display: flex;
  align-items: center;
  /* justify-content: left; */
}

.select-container {
  margin-right: auto; 
  /* margin-left :-2%; */
  margin-left :-15px;

}

.click-container {
  margin-left: 1px; 
}

.click-box {
  margin-right: 2%; 
}

/* .browser-default.custom-select {
  width: auto;
} */