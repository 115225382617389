.leftsidebar {
  width: 60px;
  display: contents;
}
.contentPanel {
  position: absolute;
  left: 0px;
  width: 60px;
  height: calc(100vh - var(--navheader-height));
  box-shadow: 2px 0px 10px 5px rgba(0, 0, 0, 0.2),
    0 0px 10px 5px rgba(0, 0, 0, 0.1);
  z-index: 50;
}

.contentPanel > button {
  font-size: 11px;
  display: block;
  outline: 0px;
  text-align: left;
  background: none;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  position: relative;
  z-index: 1;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
  height: 60px;
  padding: 4px 0;
  color: #fff;
  text-align: center;
  transition: color 0.25s ease;
}

.contentPanel > button:disabled {
  color: #212529 !important;
}

.contentPanel > button:focus {
  background: #25262d !important;
  outline: unset;
}

.contentPanel > button:hover {
  background: #151a1f !important;
}

.itemPanel {
  position: absolute;
  width: 300px;
  height: calc(100vh - var(--navheader-height));
  background-color: #25262d;
  box-shadow: 0px 0px 9px 9px rgba(0, 0, 0, 0.1),
    0 0 10px 3px rgba(0, 0, 0, 0.2);
  z-index: 90;
}

.itemPanel.show {
  left: 60px;
  transition: left 0.5s ease-in;
}

.itemPanel.hide {
  left: -300px;
  transition: left 0.5s ease-in;
}

.itemPanel input {
  width: 250px;
  color: white;
}

.itemPanel button > svg {
  width: 130px;
  height: 130px;
}

.itemPanel .hideItemPanel {
  position: absolute;
  width: 280px;
  right: 10px;
  bottom: 10px;
  border-radius: 5px;
}

/* .phonecase{
  width : 250px;
  margin-left : 25px;
  top : 30px;
} */

.fileInput {
  position: relative;
  top: 30px;
  left: 25px;
}

.addText {
  position: relative;
  width: 250px;
  height: 50px;
  top: 30px;
  margin-left: 25px;
}

.propertyPanel {
  position: absolute;
  width: 250px;
  height: calc(100vh - var(--navheader-height));
  right: 0px;
  background-color: #f5f5f5;
  z-index: 100;
  box-shadow: 8px 0px 20px 10px rgba(0, 0, 0, 0.2),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.propertyPanel.show {
  /* right : 0px; */
  transition: right 0.5s ease-out;
}

.propertyPanel.hide {
  /* right : -300px; */
  transition: right 0.5s ease-in-out;
}

.propertyPanel .propertyItem {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 48px;
  width: 99%;
  padding: 0px;
  outline: 0px;
  border: 1px solid #bdbdbd;
  background: #f5f5f5;
  color: black;
  transition: color 0.25s ease;
}

.propertyPanel > button:hover {
  background: #9e9e9e;
}

.propertyPanel > button:focus {
  padding: 0px;
  border: 1px solid #bdbdbd;
}

.propertyPanel > button.saveDesign {
  bottom: 10px;
  position: absolute;
  font-size: 16px;
}

div[id^="font-picker"] {
  width: 130px !important;
}

.flex {
  display: flex;
}

.propIcon {
  margin: 0px 20px;
}

.propLabel {
  width: 95px;
}

.mobile .propLabel1 {
  width: 95px;
}

.propLabel1 {
  width: 75px;
}

.propLabel2 {
  width: 120px;
}

.propertyItem .btn-outline-secondary {
  width: 120px;
  padding: 3px 2px;
  margin: 0px;
}

.btn-outline-secondary,
.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active {
  color: #6c757d !important;
  background-color: transparent !important;
  border-color: #6c757d !important;
}

.propertyItem .btn p {
  color: black;
}

.actionButton {
  width: 100px !important;
}

.actionButton.flex {
  display: flex;
}

.actionButton.flex > button {
  font-size: 13px;
  margin: 1px;
  width: 14px;
  font-size: 13px;
  padding: 3px 16px !important;
}

.actionButton.flex > button span {
  margin-left: -5px;
}

.btn-outline-secondary:not([disabled]):not(.disabled):active,
.btn-outline-secondary:not([disabled]):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: grey !important;
  border-color: grey !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

/*=========== mobile ==============*/
.toolbar {
  position: absolute;
  height: fit-content;
}

.toolbar.active {
  z-index: 10;
}

.menuPanel {
  height: 35px;
}

.setPropertyPanel {
  height: 60vh;
}

.containerItems.active {
  background: #333333ef;
}

.kindItemPanel {
  /* height : 30vh; */
}

.kindItemPanel .showItems {
  width: fit-content;
  margin: auto;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  z-index: 10;
  width: fit-content;
  height: fit-content;
}

.kindItemPanel .showItems button {
  box-shadow: 0 1.5px 2.5px 0 rgba(0, 0, 0, 0.5);
  margin: 0px;
  padding: 0px;
}

.containerItems {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.showItems button,
.itemImage button,
.itemText button,
.itemShape button {
  width: 46px;
  height: 46px;
  border-radius: 25px;
  border: none;
  outline: none;
  color: #333;
  z-index: 500;
  background: white;
}

.containerItems > div {
  width: fit-content;
  position: absolute;
  left: calc(50% - 7px);
  /* left: 50%; */
  bottom: -70px;
  opacity: 0;
  color: white;
  text-align: center;
  transition: all 0.2s ease-in-out;
  margin: 0px !important;
  padding: 0px !important;
}

div.containerItems.active > div {
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.containerItems.active div.itemImage {
  left: 15%;
  bottom: 30px;
}

.containerItems.active div.itemText {
  bottom: 100px;
  left: calc(50% - 23px);
}

.containerItems.active div.itemShape {
  left: calc(85% - 47px);
  bottom: 30px;
}

.itemImage button,
.itemText button,
.itemShape button {
  font-size: 10px;
  position: relative;
}

div.showItems.active button {
  transform: rotate(45deg);
}

.itemPanel.mobile {
  box-shadow: none;
  width: 100%;
  height: fit-content;
  position: relative;
  background: white;
  left: 0px;
}

div.itemPanel.mobile button {
  margin: 30px 0px;
  padding: 0px;
}

div.itemPanel.mobile button.btnShape > svg {
  margin: 3px;
  width: 40px;
  height: 40px;
}

div.itemPanel.mobile button.btnShape {
  height: 60px;
  width: 80px;
  border-radius: 0px !important;
}

.itemWraper {
  width: fit-content;
  margin: auto;
}

.mobile .addText {
  color: black !important;
}

div.propertyPanel.mobile {
  box-shadow: none;
  width: 100%;
  height: fit-content;
  overflow-x: scroll;
  position: unset;
}

.mobile div[id^="font-picker"].expanded ul {
  max-height: 10vh;
}

.sketch-picker {
  margin: auto;
}

.menuPanel .saveDesign {
  display: flex;
  height: 30px;
  right: 10px;
  position: absolute;
  padding: 5px 10px;
  color: #333;
  font-size: 15px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2),
    0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.menuPanel .saveDesign .propIcon {
  margin: 0px 10px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

.btnSize {
  font-size: 25px;
  color: #333;
}
