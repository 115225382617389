.device-compatibility {
  padding: 60px 30px;

  &__header {
    width: 100%;
    text-align: center;
  }

  &__subheader {
    width: 60%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 50px;
  }

  &__devices-container {
    display: flex;
    justify-content: space-between;

    .column {
      width: 28%;
    }

    .device-class {
      margin-bottom: 30px;
      &__header {
        font-size: 1.4rem;
      }

      &__list {
      }
    }
  }
}

@media only screen and (max-width: 46.375em) {
  .device-compatibility {
    &__devices-container {
      flex-direction: column;
      align-items: center;

      .column {
        width: 70%;
      }
    }
  }
}
