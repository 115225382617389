#login-modal {
  // Target modal backdrop and login modal, render with z-index above sidenav's z-index (2000 at time of writing this comment)
  & > * {
    z-index: 3000;
  }
}

.login-modal {
  &__body {
    display: flex;
    justify-content: center;
  }
}
