body {
  /* overflow : hidden; */
}

/* .container-fluid{
    padding-left: 0 !important;
    padding-right: 0 !important;

} */

.canvas-container {
  margin: auto;
}
