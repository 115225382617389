.drawingArea{
  /* width : calc( 100% - 60px );
  height : calc( 100vh - 120px ); */
  position: relative;
  margin : auto;
}

@font-face{
font-family: 'Wallpoet';
src: url('../properties/Wallpoet-Regular.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}
