.sidenav {
  height: 100vh;
  width: var(--sidenav-width);
  position: fixed;
  overflow:scroll;
  background-color: var(--color-primary);
  z-index: 2000;
  padding: 7px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;

  &__logo {
    width: 100%;
  }

  &__nav-links {
    height: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .display-QR {
      text-align: center;
      padding-bottom: 20%;
      animation: color-change 1s infinite;
      font-family: "Wallpoet", cursive;
      font-size: 30pt;
      &:hover {
        cursor: pointer;
        text-decoration: none;
        transform: scale(0.97);
      }
      &__QRlabel {
        font-size: 9px;
        text-align: center;
      }
    }

    .sidenav-link {
      color: white;
      padding: 0;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform 0.1s;
      backface-visibility: hidden;

      &--login-logout {
        margin-top: 10%;
        border-top: solid 1pt;
        padding-top: 30px;
      }

      &:hover {
        cursor: pointer;
        text-decoration: none;
        transform: scale(0.97);
      }

      &__label {
        margin-top: 5px;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .logout-modal {
    margin-top: 175px;

    &__body {
      display: flex;
      justify-content: center;

      .logout-btn {
        border: 2px solid #333;
        border-radius: 5px;
        padding: 5px 10px;
      }
    }
  }
}

// 414px
/* Media query for iPhone 16 Pro Max */
@media only screen and (max-width: 465px) {
  .sidenav {
    transition: transform 0.2s;

    &--hidden {
      transform: translateX(-100%);
    }

    // Uncomment and adjust the following if needed
    /*
    &__nav-links {
      .sidenav-link {
        &--logout {
          margin-bottom: 65px;
        }
      }
    }
    */
  }
}

// 380px
@media only screen and (max-width: 24.75em) {
  // .sidenav {
  //   &__nav-links {
  //     .sidenav-link {
  //       &--login-logout {
  //         margin-top: 42vh;
  //       }
  //     }
  //   }
  // }
}
