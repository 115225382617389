.push-it-down {
  margin-top: 60px;
}

.dashboard {
  margin: 66px 0;

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px 40px 20px;
  }

  &__link-card {
    width: 100%;
    padding: 40px;
    margin-bottom: 200px;
    max-width: 600px;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-primary);

    i {
      margin-right: 30px;
    }

    h2 {
      text-align: right;
    }

    &:hover {
      text-decoration: none;
      // color: inherit;
    }
  }
}

@media only screen and (min-width: 768px) {
  .dashboard {
    &__links {
      padding: 40px 100px 40px 100px;
    }
  }
}
