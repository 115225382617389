.custom-button {
  min-width: 165px;
  width: auto;
  letter-spacing: 0.5px;
  padding: 13px;
  font-size: 15px;
  background-color: var(--color-primary);
  color: white;
  text-transform: uppercase;
  font-family: "Roboto", cursive;
  border: 2px solid var(--color-primary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
}
.custom-button:hover {
  background-color: white;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
}

.custom-button-fb {
  width: auto;
  letter-spacing: 0.2px;
  padding: 12px;
  font-size: 12px !important;
  font-weight:500;
  background-color: #3b5998;
  color: white;
  text-transform: uppercase;
  font-family: "Roboto", cursive;
  border: 2px solid #3b5998;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
}
.custom-button-fb:hover {
  background-color: white;
  color: #3b5998;
  border: 2px solid #3b5998;
}

.google-sign-in {
  background-color: #4285f4;
  color: white;
}
.google-sign-in:hover {
  background-color: #357ae8;
  border: none;
}

.inverted {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.inverted:hover {
  background-color: black;
  color: white;
  border: none;
}
