/* Global variable declarations */
:root {
  --sidenav-width: 67px;
  --navheader-height: 56px;
  --color-primary: #1c2331;
}

#logout {
  padding: 0px 5px 0px 5px;
}

#hey {
  font-family: "Wallpoet", cursive;
  font-size: 15pt;
  color: white;
}

.padding-for-sidenav {
  padding-left: var(--sidenav-width);
}

.padding-for-navheader {
  padding-top: 0;
}

@media only screen and (max-width: 26.875em) {
  .padding-for-sidenav {
    padding-left: 0;
  }

  .padding-for-navheader {
    padding-top: var(--navheader-height);
  }
}
@media only screen and (max-width: 465px) {
  .padding-for-sidenav {
    padding-left: 0;
  }


}
.card{
  position: unset !important;
}
div.col{
  position: unset !important;
}