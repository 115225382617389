.shop-page {
  &__header {
    margin-bottom: 60px !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &--main {
      font-family: "Wallpoet", sans-serif;
      font-size: 54px;
    }

    &--sub {
      font-size: 18px;
      font-weight: 300;
    }
  }

  &__items {
    text-align: center;
    // margin: 0 50px;

    .shop-item {
      display: inline-block;
      vertical-align: middle;
      padding-top: 10px;
      margin-bottom: 30px;
      margin-right: 1%;
      margin-left: 1%;
      text-align: -webkit-center;
      border: 1px solid #bbb;
      border-radius: 5px;
      width: 30%;
      background-color: #f5f5f5;

      &__price {
        margin-top: 10%;
        color: black;
        font-size: x-large;
        font-weight: bold;
      }

      // &__header {
      //   grid-row: 1 / 2;
      //   font-family: "Wallpoet", sans-serif;
      //   width: 100%;
      //   text-align: center;
      //   margin-bottom: 20px;
      // }

      &__image {
        // grid-row: 2 / 3;
        justify-self: center;
        align-self: center;
        max-height: 85%;
        max-width: 85%;
        box-shadow: 4px 4px 10px 3px #00000063;
        border-radius: 10px;

        &--card {
          // width: 100;
          overflow: hidden;
        }

        &--cardIMG {
          transform: scale(1.5);
        }

        &--card--classic {
          max-height: 30%;
        }

        // &--card--back {
        //   max-height: 25%;
        // }

        &--phonecase {
          // max-height: 40%;
          margin-top: 15px;
          max-height: 14rem;
        }
      }

      &__edit {
        grid-row: 3 / 4;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .design-link {
          // margin-top: 20px;
          padding: 8px 15px;
          justify-self: center;
          // grid-row: 3 / 4;

          border-radius: 5px;
          border: none;
          color: #fff;
          background-color: var(--color-primary);

          &:hover {
            background-color: #202c45;
          }

          // &--card {
          //   grid-column: 1 / 2;
          // }

          // &--phonecase {
          //   grid-column: 2 / 3;
          // }

          &--disabled {
            color: #ddd;
            background-color: #a1adc4;
          }
        }

        .phonecase-select {
          width: 85%;
          max-width: 300px;

          .css-3q4ln-control:hover {
            cursor: pointer;
          }
        }
      }

      &__buy-btn {
        grid-row: 4 / 5;
        justify-self: center;
        align-self: center;
        margin: 10px 0 5px 0;
      }

      &__price {
        width: 100%;
        text-align: center;
      }
    }
  }
}

// 886px
@media only screen and (max-width: 55.375em) {
  .shop-page {
    &__header {
      margin-bottom: 25px !important;
    }

    // &__items {
    //   // display: flex;
    //   // flex-direction: column;
    //   align-items: center;

    //   .shop-item {
    //     width: 70%;
    //   }
    // }
  }
}

// 648px
@media only screen and (max-width: 40.5em) {
  // .shop-page {
  //   &__items {
  //     margin: 0;

  //     .shop-item {
  //       width: 95%;
  //     }
  //   }
  // }
}

// 380px
@media only screen and (max-width: 23.75em) {
  // .shop-page {
  //   &__items {
  //     .shop-item {
  //       &__image {
  //         &--cardIMG {
  //           transform: scale(1.5);
  //         }
  //         &--phonecase {
  //           max-height: 55%;
  //           max-width: 55%;
  //         }
  //       }
  //     }
  //   }
  // }
}

.checkbox {
  font-size: small;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3f729b;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1c2331;
}

.btn.btn-sm.indigo:hover {
  color: #fff;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5 {
  margin-bottom: 8rem !important;
}
.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -125px;
}

.sectionPage::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background: url("https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg");
  background-position: center center;
  background-size: cover;
  filter: opacity(0.1);
}

.carousel-indicators li {
  background-color: #182133 !important;
  // filter: invert(100%);
  width: 2rem !important;
  height: 0.3rem !important;
  border-radius: 0 !important;
}
.carousel-indicators {
  bottom: -25px !important;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
  display: inline-block;
  height: 50% !important;
  width: 50% !important;
}
.carousel-caption {
  right: 75% !important;
  bottom: -7% !important;
  color: #182133 !important;
  text-decoration: underline !important;
}
.card-footer {
  padding: unset !important;
}
.shake {
  animation: shake-animation 2.72s ease infinite !important;
  transform-origin: 50% 50%;
}
@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(5px, 0);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(5px, 0);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(5px, 0);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
