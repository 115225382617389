.fulfillment {

  #reviewCardUrl:active, #shortUrl:active {
    color: aqua;
  }
  #reviewCardImg:active {
    transform: scale(1.05);
  }
  #reviewCardUrl:hover, #shortUrl:hover, #reviewCardImg:hover{
    cursor: pointer
  }

  &__header {
    margin-bottom: 60px !important;
    width: 100%;
    text-align: center;
    font-family: "Wallpoet", sans-serif;

  }

  &__orders-container {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;

    .orders {
      width: 45%;

      &__header {
        width: 100%;
        text-align: center;
      }

      .order {
        border: 1px solid #888;
        border-radius: 10px;
        padding: 10px 20px;

        margin-bottom: 10px;

        p {
          line-height: 15px;
          word-wrap: break-word;
        }
      }
    }
  }
}
