.user-box {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  min-width: 300px;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
}
ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.display-namee {
  font-size: medium;
  font-weight: bold;
}
.user-group {
  font-size: smaller;
}
.user-email {
  font-size: smaller;
}

.card-image {
  border: 1px solid #ddd;
  border-radius: 50%;
  margin: 5px;
  height: 50px;
  width: 50px;
  overflow: hidden;
}
.user-box-content {
  display: flex;
  min-width: 85%;
}
.user-info {
  padding-left: 20px;
  /* margin-top: -5px; */
}
.click-counter-title {
    font-weight: 500 !important;
  font-size: 12px !important;
  margin-top: -5% !important;
}
.click-counter {
  font-size: 20px !important;
  /* font-weight: bold !important; */
}
.qr-image {
  border: 1px solid #ddd;
  padding: 5px;
  height: 50px;
  width: 50px;
  overflow: hidden;
}
.user-box:hover {
  box-shadow: 0 0 2px 0px rgba(0, 140, 186, 0.5);
}
.graph-click-box {
  margin-top: 3%;
}
.graph-click-box:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.menu-box {
  position: absolute;
  background-color: white;
  box-shadow: 2px 2px 4px rgb(167, 165, 165);
  right: 0;
  top: 0;
  min-width: 300px;
  cursor: pointer;
  overflow: scroll;
  padding: 5px 10px;
  padding-bottom: 0;
  align-items: center;
}
.menu-box-container {
  position: relative;
}
.user-menu-item {
  font-weight: bold;
  font-size: small;
}
.group-menu-item {
  font-size: medium;
}
.group-menu-item:hover {
  background-color: lightgray;
}
.user-menu-item:hover {
  background-color: lightgray;
}
.hidden {
  display: none;
}

.edit-menu {
  margin-left: 75%;
  padding-top: 10px;
}

.edit-menu-close:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.edit-menu:hover {
  transform: scale(1.2);
}
