.mytap-navbar {
  display: flex;
  // justify-content: center !important;
  height: 56px;

  .backbtn-container {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 60px;

    .nav-backbtn {
      cursor: pointer;
      color: #fff;
    }
  }

  .brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Wallpoet", cursive;
    font-size: 16pt;
    text-align: center;
    color: white;
  }

  .display-name {
    width: 100%;

    text-align: right;
    animation: color-change 1s infinite;
    font-family: "Wallpoet", cursive;
    font-size: 20pt;
  }

  .display-QR {
    // text-align: center;
    // padding-bottom: 20%;
    animation: color-change 1s infinite;
    // font-family: "Wallpoet", cursive;
    position: relative;
    font-size: 200%;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      transform: scale(0.97);
    }
    &__QRlabel {
      font-size: 9px;
      text-align: center;
    }
  }

  .sidenav-toggler {
    display: none;
  }

  @keyframes color-change {
    0% {
      color: rgba(253, 187, 45, 1);
    }
    25% {
      color: white;
    }
    50% {
      color: #26a69a;
    }
    100% {
      color: rgba(253, 187, 45, 1);
    }
  }
}

// 414px
@media only screen and (max-width: 26.875em) {
  .mytap-navbar {
    position: fixed !important;
    width: 100%;
    margin-top: -56px;
    z-index: 110;

    .sidenav-toggler {
      position: absolute;
      display: block;
      left: 7px;
      margin-right: auto;
      cursor: pointer;
      color: #fff;
    }

    .display-name {
      text-align: right;
    }
  }
}

@media only screen and (max-width: 465px) {
  .mytap-navbar {
    .sidenav-toggler {
      position: absolute;
      display: block;
      left: 7px;
      margin-right: auto;
      cursor: pointer;
      color: #fff;
    }

    .display-name {
      text-align: right;
    }
  }
}