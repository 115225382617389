/* .container-div {
    padding: 50px;
    height: 100%;
    background-color: lightgray;
} */
/* .inner-div {
    background-color: whitesmoke;
    padding: 20px;
    height: 100%;
    border-radius: 2%;
} */

.group-container {
    padding-top: 20px;
    padding-bottom: 10px;
}
