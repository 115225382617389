.order-page {
  &__header {
    // margin-bottom: 60px !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &--main {
      font-family: "Wallpoet", sans-serif;
      font-size: 54px;;
    }

    &--sub {
      font-size: 18px;
      font-weight: 300;
    }
  }

  &__items {
    display: flex;
    justify-content: space-around;
    margin: 0 50px;

    .shop-item {
      // height: 500px;
      padding: 10px 0 0 0;
      // margin-bottom: 30px;
      display: block;
      // text-align-last: center;
      text-align: -webkit-center;
      grid-template-rows: auto 28vh 1.5fr 1fr auto;
      border: 1px solid #bbb;
      border-radius: 5px;
      width: 75%;
    background-color:#f5f5f5;


      &__price {
        color: black;
        font-size: x-large;
        font-weight: bold;
      }

      &__header {
        grid-row: 1 / 2;
        font-family: "Wallpoet", sans-serif;
        width: 100%;
        text-align: center;
        // margin-bottom: 20px;
      }

      &__image {
        grid-row: 2 / 3;
        justify-self: center;
        align-self: center;
        max-height: 85%;
        max-width: 85%;
        box-shadow: 4px 4px 10px 3px #00000063;
        border-radius: 10px;
     
        &--card {
          // width: 100;
          overflow:hidden;
        }
        
        &--cardIMG {
          transform: scale(1.5)
        }

        &--card--classic {
          max-height: 30%;
        }

        &--phonecase {
          max-height: 35%;
        }
      }

      &__edit {
        grid-row: 3 / 4;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .design-link {
          // margin-top: 20px;
          padding: 8px 15px;
          justify-self: center;
          // grid-row: 3 / 4;

          border-radius: 5px;
          border: none;
          color: #fff;
          background-color: var(--color-primary);

          &:hover {
            background-color: #202c45;
          }

          // &--card {
          //   grid-column: 1 / 2;
          // }

          // &--phonecase {
          //   grid-column: 2 / 3;
          // }

          &--disabled {
            color: #ddd;
            background-color: #a1adc4;
          }
        }

       
      }

      &__buy-btn {
        grid-row: 4 / 5;
        justify-self: center;
        align-self: center;
        margin: 10px 0 5px 0;
      }

      &__price {
        width: 100%;
        text-align: center;
      }
    }
  }
}

// 886px
@media only screen and (max-width: 55.375em) {
  .shop-page {
    &__header {
      margin-bottom: 25px !important;
    }

    &__items {
      display: flex;
      flex-direction: column;
      align-items: center;

      .shop-item {
        width: 50%;
      }
    }
  }
}

// 648px
@media only screen and (max-width: 40.5em) {
  .shop-page {
    &__items {
      margin: 0;

      .shop-item {
        width: 95%;
      }
    }
  }
}

// 380px
@media only screen and (max-width: 23.75em) {
  // .shop-page {
  //   &__items {
  //     .shop-item {
  //       &__image {
  //         max-height: 75%;
  //         max-width: 75%;
  //         &--phonecase {
  //             max-height: 55%;
  //             max-width: 55%;
  //           }
  //       }
  //     }
  //   }
  // }
}
.checkbox{
  margin-right: 2%;
  font-size: small;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3f729b;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1c2331;
}

.btn.btn-sm.indigo:hover {
  color: #fff;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5 {
  margin-bottom: 8rem !important;
}
.sectionPage {
  position: relative;
  top: -15px;
  margin-bottom: -125px;
}

.sectionPage::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background: url("https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg");
  background-position: center center;
  background-size: cover;
  filter: opacity(0.1);
}
